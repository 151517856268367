$(function(){
	$('.about .tab-nav a').on('click', function(){
		var target = $(this).attr('href');

		$('.about .tab-nav a').removeClass('active');
		$('.about .tabs article').removeClass('active');

		$(this).addClass('active');
		$(target).addClass('active');

		return false;
	});
});