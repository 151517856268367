;(function(){
	var navToggle = document.querySelector('.util-mobile-menu'),
		navbar = document.querySelector('.navbar'),
		parentItems = navbar.querySelectorAll('.menu-item-has-children > a');

	if(navToggle){
		navToggle.addEventListener('click', function(e){
			e.preventDefault();

			this.classList.toggle('active');
			navbar.classList.toggle('active');
			document.querySelector('body').classList.toggle('nav-shown');
		});
	}

	if(parentItems){
		for(var i = 0; i < parentItems.length; i++){
			parentItems[i].addEventListener('click', function(e){
				e.preventDefault();
				var parentEl = this.parentNode;

				parentEl.querySelector('ul').classList.toggle('expanded');
				//this.nextSibling.classList.toggle('expanded');
			});
		}
	}

})();