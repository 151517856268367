;(function(){
	var mapEl = document.querySelector('.map-container'),
		mapToggle = document.querySelector('.map .toggle-btn');

	if(mapToggle){
		mapToggle.addEventListener('click', function(e){
			e.preventDefault();

			if(mapToggle.getAttribute('data-status') == 'visible'){
				mapEl.classList.add('hide');
				mapToggle.setAttribute('data-status','hidden');
				mapToggle.innerHTML = 'Show Map';
			}else{
				mapEl.classList.remove('hide');
				mapToggle.setAttribute('data-status','visible');
				mapToggle.innerHTML = 'Hide Map';
			}
		});
	}

	if(mapEl){
		window.initMap = function(){
			var latlngPos = {lat: 41.1218997, lng:-85.0504294};

			if(document.querySelector('body').classList.contains('home')){
				var icon = 'https://messiahlc.com/wp-content/themes/messiah_2018/img/pin-default.svg';
			}else{
				var icon = 'https://messiahlc.com/wp-content/themes/messiah_2018/img/pin-default.svg';
			}

			var map = new google.maps.Map(mapEl, {
				center: latlngPos,
				disableDefaultUI: true,
				zoom: 13
			});

			var customMarker = {
				url:icon,
				size: new google.maps.Size(54, 80),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(27, 70)
			};

			var marker = new google.maps.Marker({
				position:latlngPos,
				map:map,
				title:'Messiah Lutheran Church',
				icon:customMarker
			});
		}
	}else{
		window.initMap = function(){

		}
	}
})();